import { IconProps } from '../lib'

const MoreHorizontalIcon16: React.FC<Omit<IconProps, 'size'>> = ({
  className,
}) => {
  return (
    <svg
      width="16"
      height="16"
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="more-horizontal-16">
        <g id="Icon">
          <path d="M1 9.5V6.5H4V9.5H1Z" fill="currentColor" />
          <path d="M6.5 9.5V6.5H9.5V9.5H6.5Z" fill="currentColor" />
          <path d="M12 6.5V9.5H15V6.5H12Z" fill="currentColor" />
        </g>
      </g>
    </svg>
  )
}

export { MoreHorizontalIcon16 }
