import { IconProps } from '../lib'

const TransferIcon16: React.FC<Omit<IconProps, 'size'>> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="transfer-16">
        <path
          id="Icon"
          d="M2.5 1V9.25L13.1287 9.25001L8.87867 5H11L15 9V11L11 15H8.8787L13.1287 10.75H1V1H2.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export { TransferIcon16 }
