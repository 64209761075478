import { IconProps } from '../lib'

const CloseIcon16: React.FC<Omit<IconProps, 'size'>> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="close-16">
        <path
          id="Icon"
          d="M6.93934 8.00001L0.939331 2H3.06065L8 6.93935L12.9394 2H15.0607L9.06066 8.00001L15.0607 14H12.9393L8 9.06067L3.06067 14H0.939351L6.93934 8.00001Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export { CloseIcon16 }
