import { IconProps } from '../lib'

const ThumbIcon24: React.FC<Omit<IconProps, 'size'>> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="thumb">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.625 3V8.25H22.5V12.75L19.5 22.5H1.5V10.5H6L10.5 1.5L14.625 3ZM6 12H3V21H6V12ZM7.5 21V10.8541L11.2438 3.36656L13.125 4.05064V9.75H21V12.5244L18.3921 21H7.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export { ThumbIcon24 }
