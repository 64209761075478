import { IconProps } from '../lib'

const ProjectIcon24: React.FC<Omit<IconProps, 'size'>> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="project">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1.5L21.0933 6.75V17.25L12 22.5L2.90674 17.25V6.75L12 1.5ZM4.40674 7.61603L12 3.23205L19.5933 7.61603V16.384L18 17.3039V11.5226L13.5 14.1154V19.9019L12 20.768L4.40674 16.384V7.61603ZM15 19.0359L16.5 18.1699V14.118L15 14.9823V19.0359Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export { ProjectIcon24 }
