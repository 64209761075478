import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import { useAnalytics } from '../../../../hooks/use-analytics'
import LabelLarge from '../../data-display/typography/label-large/label-large'
import LabelMedium from '../../data-display/typography/label-medium/label-medium'

import styles from './button.module.css'

interface ButtonProps extends PropsWithChildren {
  /**
   * For standardizing click event names: `${analyticsPrefix} Button Clicked`.
   * Should not be used if a button routes to a different page.
   * Optional for now, but we should move towards required.
   */
  analyticsPrefix?: string
  /**
   * Optional extra class to add to the button.
   */
  className?: string
  /**
   * Is the button clickable?
   */
  disabled?: boolean
  /**
   * Icon to display next to button label
   */
  icon?: React.ReactNode
  /**
   * Default alignment is 'left'.
   */
  iconAlign?: 'left' | 'right'
  /**
   * Is the button currently loading?
   */
  isLoading?: boolean
  /**
   * Button text label
   */
  label?: string
  /**
   * Html button types defaults to "button".
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
   */
  type?: 'button' | 'submit' | 'reset'
  /**
   * Which button type should we render? Defaults to `ButtonType.Primary`
   */
  variant?: ButtonVariant
  /**
   * Default size is medium.
   */
  size?: ButtonSize
  /**
   * Button state is enabled by default.
   */
  state?: ButtonState
  /**
   * Optional click handler
   */
  onClick?: (event: React.MouseEvent) => void
}

/**
 * Buildforce button variants.
 *   - Primary
 *   - Secondary
 *   - Danger
 *   - Ghost
 *
 * https://www.figma.com/file/sLANzqb5jgXvbeDGYD32Wc/Buildforce-%7C-Shared-Design-System
 */
export enum ButtonVariant {
  /**
   * Principle call to action on a page or screen.
   */
  Primary,
  /**
   * Should be paired with the primary button and trigger a negative action within a set.
   */
  Secondary,
  /**
   * Important call to action, but not the principle. Subtle.
   */
  Tertiary,
  /**
   * Used for actions that are considered destructive and could have effects on the uesr's data.
   */
  Danger,
  /**
   * Used for least pronounced actions. Can be paired with primary or secondary buttons.
   */
  Ghost,
  /**
   * Is a button, but styled like a link.
   */
  Link,
  /**
   * Contains children
   */
  WithChildren,
}

export enum ButtonState {
  Enabled,
  Error,
}

export enum ButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

/**
 * Primary UI component for user interaction
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    analyticsPrefix,
    children,
    disabled = false,
    icon,
    iconAlign,
    isLoading = false,
    className,
    label,
    onClick,
    type = 'button',
    variant = ButtonVariant.Primary,
    size = ButtonSize.Medium,
    state = ButtonState.Enabled,
    ...props
  },
  ref
) {
  const analytics = useAnalytics()

  const handleOnClick = (event: React.MouseEvent) => {
    !!analyticsPrefix && analytics.track(`${analyticsPrefix} Button Clicked`)
    if (typeof onClick === 'function') onClick(event)
  }

  const buttonLabel =
    size === ButtonSize.Large ? (
      <LabelLarge>{label}</LabelLarge>
    ) : (
      <LabelMedium>{label}</LabelMedium>
    )

  const getButtonLabelWithIcon = () => {
    return iconAlign === 'right' ? (
      <>
        {buttonLabel}
        {icon}
      </>
    ) : (
      <>
        {icon}
        {buttonLabel}
      </>
    )
  }

  return (
    <button
      ref={ref}
      type={type}
      disabled={disabled || isLoading}
      className={classNames(
        styles.button,
        styles[`button-${size.toLowerCase()}`],
        'transition',
        className,
        {
          [styles.error]: state === ButtonState.Error,
          [styles.primary]: variant === ButtonVariant.Primary,
          [styles.secondary]: variant === ButtonVariant.Secondary,
          [styles.tertiary]: variant === ButtonVariant.Tertiary,
          [styles.danger]: variant === ButtonVariant.Danger,
          [styles.link]: variant === ButtonVariant.Link,
          [styles.withChildren]: variant === ButtonVariant.WithChildren,
          [styles.loading]: isLoading,
          [styles.disabled]: disabled,
        }
      )}
      onClick={onClick ? handleOnClick : undefined}
      {...props}
    >
      {icon ? getButtonLabelWithIcon() : !!label && buttonLabel}
      {children}
    </button>
  )
})

export default Button
