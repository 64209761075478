import { IconProps } from '../lib'

const WorkersIcon16: React.FC<Omit<IconProps, 'size'>> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="workers-16">
        <g id="Icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.25 7.5C6.04493 7.5 7.5 6.04493 7.5 4.25C7.5 2.45507 6.04493 1 4.25 1C2.45507 1 1 2.45507 1 4.25C1 6.04493 2.45507 7.5 4.25 7.5ZM4.25 6C5.2165 6 6 5.2165 6 4.25C6 3.2835 5.2165 2.5 4.25 2.5C3.2835 2.5 2.5 3.2835 2.5 4.25C2.5 5.2165 3.2835 6 4.25 6Z"
            fill="currentColor"
          />
          <path d="M11 7.5V9H13.5V11.5H15V7.5H11Z" fill="currentColor" />
          <path d="M1 9V10.5H8V15H9.5V9H1Z" fill="currentColor" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 3.5C14 4.88071 12.8807 6 11.5 6C10.1193 6 9 4.88071 9 3.5C9 2.11929 10.1193 1 11.5 1C12.8807 1 14 2.11929 14 3.5ZM12.5 3.5C12.5 4.05228 12.0523 4.5 11.5 4.5C10.9477 4.5 10.5 4.05228 10.5 3.5C10.5 2.94772 10.9477 2.5 11.5 2.5C12.0523 2.5 12.5 2.94772 12.5 3.5Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  )
}

export { WorkersIcon16 }
