import { IconProps } from '../lib'

const AddIcon16: React.FC<Omit<IconProps, 'size'>> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="add-16">
        <path
          id="Icon"
          d="M7.25 8.75V15H8.75V8.75H15V7.25H8.75V1H7.25V7.25H1V8.75H7.25Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export { AddIcon16 }
