import cls from 'classnames'
import React from 'react'

import { TextColorProp, TextWeightProp, overrides } from '../lib'

interface LabelLargeProps extends TextColorProp, TextWeightProp {
  className?: string
}

const LabelLarge: React.FC<React.PropsWithChildren<LabelLargeProps>> = ({
  children,
  className,
  weight = 'bold',
  color,
}) => {
  return (
    <p
      className={cls('label-large', className, ...overrides({ weight, color }))}
    >
      {children}
    </p>
  )
}

export default LabelLarge
