import cls from 'classnames'
import React from 'react'

import { TextColorProp, TextWeightProp, overrides } from '../lib'

interface LabelMediumProps extends TextColorProp, TextWeightProp {
  className?: string
}

const LabelMedium: React.FC<React.PropsWithChildren<LabelMediumProps>> = ({
  className,
  color,
  weight = 'bold',
  children,
}) => {
  return (
    <p
      className={cls(
        'label-medium',
        className,
        ...overrides({ weight, color })
      )}
    >
      {children}
    </p>
  )
}

export default LabelMedium
