import { MouseEventHandler } from 'react'

import { AddIcon16 } from './16/add-icon-16'
import { CloseIcon16 } from './16/close-icon-16'
import { MoonIcon16 } from './16/moon-icon-16'
import { MoreHorizontalIcon16 } from './16/more-horizontal-icon-16'
import { TimeIcon16 } from './16/time-icon-16'
import { TransferIcon16 } from './16/transfer-icon-16'
import { WorkersIcon16 } from './16/workers-icon-16'
import { ProjectIcon24 } from './24/project-icon-24'
import { ThumbIcon24 } from './24/thumb-icon-24'
import CaretDown from './caret-down-icon'
import CloseIcon from './close-icon'

export interface IconProps {
  /**
   * Size of the icon, defaults to 'medium'
   */
  size?: IconSizes
  /**
   * Class name to append to the icon.
   */
  className?: string
  /**
   * Optional on click handler.
   */
  onClick?: MouseEventHandler<any>
}

/**
 * Default icon size.
 */
export const DEFAULT_ICON_SIZE: IconSizes = 'medium'

/**
 * Available icon sizes.
 */
export type IconSizes = 'x-small' | 'small' | 'medium' | 'large' | 'x-large'

/**
 * Returns string value for width and height of icon.
 */
export const getSize = (size: IconSizes): string => {
  switch (size) {
    case 'x-small':
      return '10'
    case 'small':
      return '16'
    case 'medium':
      return '24'
    case 'large':
      return '40'
    case 'x-large':
      return '64'
    default:
      return DEFAULT_ICON_SIZE
  }
}

export type IconKey =
  | 'transfer-16'
  | 'caret-down'
  | 'cancel'
  | 'more-horizontal-16'
  | 'add-16'
  | 'workers-16'
  | 'moon-16'
  | 'project-24'
  | 'thumb-24'
  | 'close-16'
  | 'time-16'

/**
 * Returns an icon button component.
 *
 * @param icon Key of the icon to render
 * @param props Icon props
 * @returns An icon button with the desired icon.
 */
export function getIcon(icon: IconKey, props?: IconProps) {
  switch (icon) {
    case 'transfer-16':
      return <TransferIcon16 {...props} />
    case 'workers-16':
      return <WorkersIcon16 {...props} />
    case 'caret-down':
      return <CaretDown {...props} />
    case 'close-16':
      return <CloseIcon16 {...props} />
    case 'cancel':
      return <CloseIcon {...props} />
    case 'more-horizontal-16':
      return <MoreHorizontalIcon16 {...props} />
    case 'add-16':
      return <AddIcon16 {...props} />
    case 'moon-16':
      return <MoonIcon16 {...props} />
    case 'project-24':
      return <ProjectIcon24 {...props} />
    case 'thumb-24':
      return <ThumbIcon24 {...props} />
    case 'time-16':
      return <TimeIcon16 {...props} />
    default:
      throw new Error(`Unknown icon: ${icon}`)
  }
}
