import { IconProps } from '../lib'

const MoonIcon16: React.FC<Omit<IconProps, 'size'>> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="moon">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.10233 11.8977C5.17894 12.9743 6.44884 13.5 8 13.5C9.55116 13.5 10.8211 12.9743 11.8977 11.8977C12.4818 11.3135 12.9038 10.6724 13.1676 9.96039C12.6329 10.1212 12.075 10.2 11.5 10.2C9.94005 10.2 8.56551 9.63683 7.46434 8.53566C6.36317 7.43449 5.8 6.05995 5.8 4.5C5.8 3.92503 5.88099 3.3668 6.044 2.83075C5.33021 3.09445 4.6877 3.51696 4.10233 4.10233C3.02571 5.17894 2.5 6.44884 2.5 8C2.5 9.55116 3.02571 10.8211 4.10233 11.8977ZM6.93627 1.07188C5.44559 1.27935 4.14739 1.93595 3.04167 3.04167C1.68056 4.40278 1 6.05556 1 8C1 9.94444 1.68056 11.5972 3.04167 12.9583C4.40278 14.3194 6.05556 15 8 15C9.94444 15 11.5972 14.3194 12.9583 12.9583C14.0632 11.8535 14.7196 10.5565 14.9276 9.06726C14.9759 8.72184 15 8.36609 15 8C15 7.81852 14.9935 7.64028 14.9806 7.46528C14.9676 7.29028 14.9481 7.11852 14.9222 6.95C14.7808 7.14997 14.6237 7.3348 14.4511 7.5045C14.2652 7.68722 14.0612 7.85239 13.8391 8C13.7295 8.07291 13.6154 8.14153 13.4969 8.20588C13.4859 8.21182 13.4749 8.21774 13.4639 8.22361C12.8676 8.5412 12.213 8.7 11.5 8.7C10.3333 8.7 9.34167 8.29167 8.525 7.475C7.70833 6.65833 7.3 5.66667 7.3 4.5C7.3 3.8 7.4588 3.14861 7.77639 2.54583C7.78395 2.53149 7.79157 2.5172 7.79925 2.50298C7.86211 2.38661 7.92902 2.27455 8 2.16679C8.14758 1.94271 8.31271 1.73725 8.49539 1.55039C8.66511 1.37679 8.84998 1.21925 9.05 1.07778C8.88148 1.05185 8.70972 1.03241 8.53472 1.01944C8.35972 1.00648 8.18148 1 8 1C7.63515 1 7.28058 1.02396 6.93627 1.07188Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export { MoonIcon16 }
