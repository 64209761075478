import { DEFAULT_ICON_SIZE, getSize, IconProps } from './lib'

const CloseIcon: React.FC<IconProps> = ({
  size = DEFAULT_ICON_SIZE,
  className,
}) => {
  const iconSize = getSize(size)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M2.18945 3.83325L11.1895 12.8333L2.18947 21.8333H4.3108L12.2501 13.8939L20.1895 21.8333H22.3108L13.3108 12.8333L22.3108 3.83325H20.1895L12.2501 11.7726L4.31077 3.83325H2.18945Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  )
}

export default CloseIcon
