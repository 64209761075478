import { DEFAULT_ICON_SIZE, getSize, IconProps } from './lib'

/**
 * CaretDown SVG Icon
 */
const CaretDown: React.FC<IconProps> = ({
  size = DEFAULT_ICON_SIZE,
  className,
}) => {
  const iconSize = getSize(size)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.09 9.09L18.5 10.5L12.5 16.5L6.5 10.5L7.91 9.09L12.5 13.67L17.09 9.09Z"
        fill="currentColor"
      />
      <mask
        id="mask0_3_227"
        maskUnits="userSpaceOnUse"
        x="6"
        y="9"
        width="13"
        height="8"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.09 9.09L18.5 10.5L12.5 16.5L6.5 10.5L7.91 9.09L12.5 13.67L17.09 9.09Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3_227)"></g>
    </svg>
  )
}

export default CaretDown
